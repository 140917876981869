<template>
  <contacts-add-edit v-model="contact" title="Add Location Contact" :entity-name="location.name" @onSave="save">
    <template #description>
      <p>An email will be sent to this contact to create an account used to login to the Client Portal. Contacts added to this location will only have access to this location.</p>
    </template>
  </contacts-add-edit>
</template>
<script>
import ContactsAddEdit from "@/modules/shared/contacts/ContactsAddEdit";
import { ADD_LOCATION_ASSIGNMENTS, INSERT_LOCATION_CONTACT_NEW_USER } from "@/modules/shared/contacts/graph/mutations";
import { GET_LOCATION_NAME } from "@/modules/admin/locations/graph/query";

export default {
  components: { ContactsAddEdit },
  data: () => ({
    location: {
      name: "",
    },
    contact: {
      user: {},
      address: {},
    },
  }),
  computed: {
    locationID() {
      return this.location.id || this.$route.params.locationID;
    },
  },
  methods: {
    save(contact) {
      this.$apollo
        .mutate({
          mutation: INSERT_LOCATION_CONTACT_NEW_USER,
          variables: {
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone.replace(/\D/g, ""),
            email: contact.email.toLowerCase(),
            password: "random",
            address1: contact.address.address1,
            address2: contact.address.address2,
            city: contact.address.city,
            postalCode: contact.address.postalCode,
            state: contact.address.state,
            country: contact.address.country,
            role: contact.user.role,
          },
        })
        .then(({ data }) => {
          const contactID = data.contact.id;
          const locationAssignments = [{ clientLocationID: this.locationID, contactID }];
          this.$apollo.mutate({
            mutation: ADD_LOCATION_ASSIGNMENTS,
            variables: {
              objects: locationAssignments,
            },
          });
        })
        .finally(() => this.$router.back());
    },
  },
  apollo: {
    location: {
      query: GET_LOCATION_NAME,
      variables() {
        return {
          id: this.$route.params.locationID,
        };
      },
      update: (data) => data.location,
    },
  },
};
</script>
